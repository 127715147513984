import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/auth/interfaces/login.interfaces';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SellosService {

  baseUrl: string = environment.baseUrl;
  public user: User;
  public token: string;
  constructor(private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('user')) || {};
    this.token = JSON.parse(localStorage.getItem('access_token'))
  }
  verificarEmpaque(datos:any): Observable<any> {
    return this.http.post(this.baseUrl + '/sellos/verificarempaque', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  empacar(datos:any): Observable<any> {
    return this.http.post(this.baseUrl + '/sellos/empacar', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  instalar(datos:any): Observable<any> {
    return this.http.post(this.baseUrl + '/sellos/instalar', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  inventario(parameters?:any): Observable<any> {
    return this.http.get(this.baseUrl + '/sellos/inventario',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      }),params: parameters
    });
  }

  getTrazabilidadLecturas(parameters?:any): Observable<any> {
    return this.http.get(this.baseUrl + '/sellos/inventario/lecturas',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      }),params: parameters
    });
  }

  getTrazabilidadRemisiones(parameters?:any): Observable<any> {
    return this.http.get(this.baseUrl + '/sellos/inventario/remisiones',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      }),params: parameters
    });
  }


  getAll(): Observable<any> {
    return this.http.get(this.baseUrl + '/sellos',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });

  }

  add(datos): Observable<any> {
    return this.http.post(this.baseUrl + '/sellos', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }

  update(datos, id): Observable<any> {
    return this.http.put(this.baseUrl + '/sellos/'+id, datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }

  delete(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/sellos/'+id,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  lotesDespacho():Observable<any> {
    return this.http.get(this.baseUrl + '/sellos/lotesdespacho',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  lotesDespachoProducto():Observable<any> {
    return this.http.get(this.baseUrl + '/sellos/lotesdespachoproducto',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  subirInstalaciones(data:FormData){
    return this.http.post(this.baseUrl + '/sellos/subirinstalaciones',data,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    }).pipe(
      catchError((err) => {
        return throwError(err);    //Rethrow it back to component
      })
    );
  }
  pdfTrazabilidad(): Observable<any> {
    return this.http.get(this.baseUrl + '/trazabilidad',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }

  getEvidencias(id): Observable<any> {
    return this.http.get(this.baseUrl + `/sellos/inventario/evento/${id}/evidencias`,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
}
